define("discourse/plugins/chat/discourse/components/chat-channel-leave-btn", ["exports", "@glimmer/component", "@ember/service", "@ember/utils", "discourse/components/d-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _utils, _dButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatChannelLeaveBtn;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelLeaveBtn = _exports.default = (_class = (_ChatChannelLeaveBtn = class ChatChannelLeaveBtn extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
    }
    get shouldRender() {
      return this.site.desktopView && (0, _utils.isPresent)(this.args.channel);
    }
    get leaveChatTitleKey() {
      if (this.args.channel.isDirectMessageChannel) {
        return "chat.direct_messages.leave";
      } else {
        return "chat.leave";
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.shouldRender}}
        <DButton
          @icon="times"
          @action={{@onLeaveChannel}}
          @title={{this.leaveChatTitleKey}}
          class="btn-flat chat-channel-leave-btn"
        />
      {{/if}}
    
  */
  {
    "id": "pGLrTftt",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-flat chat-channel-leave-btn\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,1],[30,0,[\"leaveChatTitleKey\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@onLeaveChannel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-leave-btn.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _ChatChannelLeaveBtn), _ChatChannelLeaveBtn), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});